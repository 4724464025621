import React from 'react';

import moment from 'moment';

import classNames from 'classnames';
import InfoButton from '../InfoButton';

import classes from './styles.module.scss';
import folderIcon from '../../../../assets/images/my-library/folder2.svg';
import subfolderIcon from '../../../../assets/images/my-library/subfolder2.svg';
import downChevron from '../../../../assets/images/down-chevron.svg';

export default function FolderRow({
  row,
  nestLevel,
  showSummaryReportModal,
  isExport,
  setExpandedFolderIds,
  isExpanded,
  hasContent,
}) {
  const isSubfolder = !!row.parentId;

  return (
    <tr
      className={classNames(classes.FolderRow, {
        [classes.isSubfolder]: isSubfolder,
        [classes.isExpanded]: isExpanded,
      })}
      onClick={() => {
        if (!isSubfolder || !hasContent) {
          return;
        }

        setExpandedFolderIds((prev) => {
          if (prev.includes(row.id)) {
            return prev.filter((id) => id !== row.id);
          }

          return [...prev, row.id];
        });
      }}
    >
      <td>
        <div
          style={{
            position: 'relative',
            left: `${(nestLevel - 1) * 40}px`,
          }}
        >
          {isSubfolder && hasContent && !isExport && (
            <img src={downChevron} className={classes.chevron} alt="Chevron" />
          )}
          <img src={isSubfolder ? subfolderIcon : folderIcon} alt="" />
          <span className={classes.name}>{row.name}</span>
        </div>
      </td>
      <td>{moment(row.updatedAt).format('MMM DD, YYYY')}</td>
      <td>{row.meetingCount}</td>
      <td />
      <td />
      <td />
      {!isExport && (
        <td>
          {row.meetingCount > 0 && (
            <InfoButton
              onClick={() =>
                showSummaryReportModal(
                  row.id,
                  isSubfolder ? 'subfolder' : 'folder',
                  row.name
                )
              }
            />
          )}
        </td>
      )}
    </tr>
  );
}
